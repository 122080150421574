<script>
import { mapGetters } from "vuex";
import store from "@/state/store";

import { CountTo } from "vue3-count-to";
import Swal from "sweetalert2";

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";

import CampaignServices from "@/services/CampaignServices";

export default {
  page: {
    title: "Campagne détails",
  },
  data() {
    return {
      title: "Campagne détails",
      searchQuery: "",
      items: [
        {
          text: "GICAM",
          href: "/",
        },
        {
          text: "Campagnes",
          active: true,
        },
        {
          text: "Détails",
          active: true,
        },
      ],
      loadings: [],
    };
  },
  components: {
    Layout,
    PageHeader,
    CountTo,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch("campaigns/findOne", to.params.campaignId);

    next();
  },
  methods: {
    async handleDiffuseCampaign() {
      this.$startLoading();

      await CampaignServices.diffuse(this.currentCampaign.id)
        .then(async ({ data }) => {
          if (data.error === false) {
            await this.$store.dispatch(
              "campaigns/findOne",
              this.$route.params.campaignId
            );

            this.$saSuccess("Les SMS ont été diffusés.");
          } else {
            this.$saError("Erreur lors de la diffusion !");
          }
        })
        .catch(() => {
          this.$saError("Erreur lors de la diffusion !");
        });
    },
    async handleDeleteCampaign() {
      let confirmDelete = false;

      await Swal.fire({
        text: "Confirmez-vous la suppresssion de cette campagne ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, confirmer",
        cancelButtonText: "Non, annuler",
        confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
        cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          confirmDelete = true;
        }
      });

      if (!confirmDelete) {
        return;
      }

      this.$startLoading();

      await CampaignServices.delete(this.currentCampaign.id)
        .then(async ({ data }) => {
          if (data.error === false) {
            this.$router.replace({ name: "campagnes-sms" });

            this.$saSuccess("Campagne effacée !");
          } else {
            this.$saError("Erreur lors de la suppression !");
          }
        })
        .catch(() => {
          this.$saError("Erreur lors de la suppression !");
        });
    },
    async handleResendSMS(target) {
      if (this.loadings.includes(target.id)) return;

      let resend = false;

      await Swal.fire({
        text: "Voulez-vous vraiment renvoyer le message ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, renvoyer",
        cancelButtonText: "Non, annuler",
        confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
        cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          resend = true;
        }
      });

      if (!resend) {
        return;
      }

      this.loadings.push(target.id);
      const index = this.loadings.length - 1;

      await CampaignServices.resend(this.currentCampaign.id, target.id)
        .then(({ data }) => {
          if (data.error === false) {
            Swal.fire({
              icon: "success",
              text: "Message renvoyé !",
            });
          } else {
            Swal.fire({
              icon: "error",
              text: "Erreur lors du renvoi du message !",
            });
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            text: "Erreur lors du renvoi du message !",
          });
        })
        .finally(() => {
          this.loadings.splice(index, 1);
          store.dispatch("campaigns/findOne", this.$route.params.campaignId);
        });
    },
  },
  computed: {
    ...mapGetters("campaigns", ["campaigns", "currentCampaign"]),
    ...mapGetters("system", ["isAdmin"]),
    totalDone() {
      return (
        this.currentCampaign.targets?.filter((c) => c.status == 1).length ?? 0
      );
    },
    totalFailed() {
      return (
        this.currentCampaign.targets?.filter((c) => c.status == 2).length ?? 0
      );
    },
    percentageSuccess() {
      return Math.round(
        (this.totalDone / this.currentCampaign.targets.length) * 100
      );
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.currentCampaign.targets.filter((data) => {
          return (
            data.target_name?.toLowerCase().includes(search) ||
            data.target_phone?.toLowerCase().includes(search)
          );
        });
      } else {
        return this.currentCampaign.targets;
      }
    },
  },
};
</script>

<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="diffusionConfirmModal"
    tabindex="-1"
    aria-labelledby="diffusionConfirmModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="diffusionConfirmModalLabel">
            Confirmation
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Confirmez-vous l'envoi des SMS à tous les destinataires tout de
            suite ?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click.prevent="handleDiffuseCampaign"
          >
            Oui, confirmer
          </button>
        </div>
      </div>
    </div>
  </div>

  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="d-flex align-items-center mb-3">
        <button
          type="button"
          class="btn btn-light btn-xl"
          @click="$router.replace('/campaign/sms')"
        >
          <i class="mdi mdi-arrow-left"></i> Retour
        </button>
      </div>

      <div class="col-md-4">
        <div class="card">
          <div class="card-body p-4">
            <div>
              <div class="mt-4 text-center">
                <h5 class="mb-1">{{ currentCampaign.name }}</h5>
                <p class="text-muted">
                  Enregistré le
                  {{
                    new Date(currentCampaign.created_at).toLocaleDateString()
                  }}
                  à
                  {{
                    new Date(currentCampaign.created_at).toLocaleTimeString()
                  }}
                </p>
              </div>
              <div class="table-responsive">
                <table class="table mb-0 table-borderless">
                  <tbody>
                    <tr>
                      <th><span class="fw-medium">Message</span></th>
                      <td>{{ currentCampaign.message }}</td>
                    </tr>
                    <tr>
                      <th><span class="fw-medium">Status</span></th>
                      <td>
                        <span
                          v-if="currentCampaign.status == '0'"
                          class="badge bg-soft-warning text-warning"
                        >
                          En attente
                        </span>

                        <span v-else-if="currentCampaign.status == '1'">
                          <span>Diffusé le </span>
                          <span class="text-success">
                            {{
                              new Date(
                                currentCampaign.created_at
                              ).toLocaleDateString()
                            }}</span
                          >
                          à
                          <span class="text-success">
                            {{
                              new Date(
                                currentCampaign.created_at
                              ).toLocaleTimeString()
                            }}</span
                          >
                        </span>

                        <span
                          v-else-if="currentCampaign.status == '3'"
                          class="badge bg-soft-warning text-warning"
                        >
                          En cours d'envoie
                        </span>

                        <span v-else class="badge bg-soft-danger text-danger">
                          Erreur
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>

      <b-col md="8">
        <div class="col-xxl-12">
          <div class="card mb-5">
            <div class="card-header p-0 border-0 bg-soft-light">
              <div class="row g-0 text-center">
                <div class="col-6 col-sm-3">
                  <div class="p-3 border border-dashed border-start-0">
                    <h5 class="mb-1">
                      <count-to
                        :duration="2000"
                        :startVal="0"
                        :endVal="currentCampaign.targets.length"
                      ></count-to>
                    </h5>
                    <p class="text-muted mb-0">Cibles</p>
                  </div>
                </div>
                <!--end col-->
                <template
                  v-if="
                    !currentCampaign.sheduled ||
                    (currentCampaign.sheduled && currentCampaign.status != '0')
                  "
                >
                  <div class="col-6 col-sm-3">
                    <div class="p-3 border border-dashed border-start-0">
                      <h5 class="mb-1">
                        <count-to
                          :duration="2000"
                          :startVal="0"
                          :endVal="totalDone"
                        ></count-to>
                      </h5>
                      <p class="text-muted mb-0">Envoyés</p>
                    </div>
                  </div>
                  <div class="col-6 col-sm-3">
                    <div class="p-3 border border-dashed border-start-0">
                      <h5 class="mb-1">
                        <count-to
                          :duration="2000"
                          :startVal="0"
                          :endVal="totalFailed"
                          :class="'text-danger'"
                        ></count-to>
                      </h5>
                      <p class="text-muted mb-0">Échecs</p>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="col-6 col-sm-6"
                    :class="{ 'col-sm-6': isAdmin, 'col-sm-9': !isAdmin }"
                  >
                    <div class="p-3 border border-dashed border-start-0">
                      <h5 class="mb-1">
                        {{
                          new Date(
                            currentCampaign.diffuse_at
                          ).toLocaleDateString()
                        }}&nbsp;à
                        {{
                          new Date(
                            currentCampaign.diffuse_at
                          ).toLocaleTimeString()
                        }}
                      </h5>
                      <p class="text-muted mb-0">Diffusion programmée</p>
                    </div>
                  </div>
                </template>

                <!--end col-->
                <div
                  v-if="currentCampaign.status != '0'"
                  class="col-6 col-sm-3"
                >
                  <div
                    class="p-3 border border-dashed border-start-0 border-end-0"
                  >
                    <h5 class="mb-1">
                      <count-to
                        :duration="5000"
                        :startVal="0"
                        :endVal="percentageSuccess"
                        :class="{
                          'text-success': percentageSuccess >= 50,
                          'text-danger': percentageSuccess < 50,
                        }"
                      ></count-to
                      >&nbsp;<span
                        :class="{
                          'text-success': percentageSuccess >= 50,
                          'text-danger': percentageSuccess < 50,
                        }"
                        >%</span
                      >
                    </h5>
                    <p class="text-muted mb-0">Pourcentage</p>
                  </div>
                </div>
                <!--end col-->

                <div v-else-if="isAdmin" class="col-6 col-sm-3">
                  <div
                    class="p-2 h-100 border border-dashed border-start-0 border-end-0 d-flex justify-content-center align-items-center"
                  >
                    <b-button
                      class="ms-2"
                      variant="primary"
                      data-bs-toggle="modal"
                      data-bs-target="#diffusionConfirmModal"
                    >
                      Diffuser maintenant
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--end col-->
        <div class="col-xxl-12">
          <div class="card">
            <div class="card-header">
              <div class="d-flex justify-space-between align-items-center">
                <h5 class="w-100 mb-0">Rapport de diffusion</h5>

                <div
                  v-if="isAdmin || false"
                  class="w-100 d-flex gap-3 justify-content-end"
                >
                  <router-link
                    class="btn btn-primary"
                    :to="{
                      name: 'campagnes-sms-create',
                      query: { mode: 'clone' },
                    }"
                  >
                    <span> Cloner la diffusion </span>
                  </router-link>

                  <b-button
                    variant="danger"
                    class=""
                    @click.prevent="handleDeleteCampaign"
                  >
                    <span> Supprimer </span>
                  </b-button>
                </div>
              </div>
            </div>
            <div class="card-body p-4">
              <div class="col-lg-12">
                <div class="row mb-4">
                  <div class="col-sm">
                    <div class="d-flex justify-content-sm-end">
                      <div class="search-box ms-2">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Rechercher..."
                          v-model="searchQuery"
                        />
                        <i class="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="table-responsive table-card">
                    <table
                      class="table table-borderless align-middle"
                      style="font-size: 13px"
                    >
                      <thead class="table-light">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Nom</th>
                          <th
                            scope="col"
                            class="text-center"
                            style="min-width: 200px"
                          >
                            Téléphone
                          </th>
                          <th
                            scope="col"
                            class="text-center"
                            style="min-width: 150px"
                          >
                            Status
                          </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(doc, index) of resultQuery || []"
                          :key="index"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ doc.target_name }}</td>
                          <td class="text-center">
                            {{ doc.target_phone }}
                          </td>
                          <td class="text-center">
                            <span
                              v-if="doc.status == '0'"
                              class="badge bg-soft-warning text-warning"
                            >
                              En attente
                            </span>

                            <span
                              v-else-if="doc.status == '2'"
                              class="badge bg-soft-danger text-danger"
                            >
                              Erreur
                            </span>

                            <span
                              v-else-if="doc.status == '1'"
                              class="badge bg-soft-success text-success"
                            >
                              Envoyé
                            </span>
                          </td>

                          <td>
                            <div
                              v-if="!loadings.includes(doc.id)"
                              class="dropdown"
                            >
                              <a
                                href="javascript:void(0);"
                                class="btn btn-soft-secondary btn-sm btn-icon"
                                data-bs-toggle="dropdown"
                                aria-expanded="true"
                              >
                                <i class="ri-more-fill"></i>
                              </a>
                              <ul class="dropdown-menu dropdown-menu-end">
                                <!-- <li>
                                <a class="dropdown-item">
                                  <i
                                    class="ri-eye-open-fill me-2 align-bottom text-muted"
                                  ></i>
                                  Rapport d'envoie
                                </a>
                              </li> -->

                                <!-- <li>
                                <hr class="dropdown-divider" />
                              </li> -->

                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0);"
                                    @click.prevent="handleResendSMS(doc)"
                                    ><i
                                      class="ri-refresh-line me-2 align-bottom text-muted"
                                    ></i>
                                    Renvoyer
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <span
                              v-else
                              class="spinner-border spinner-border-sm text-warning"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </b-col>
    </div>
    <!--end row-->
  </Layout>
</template>
